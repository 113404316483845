var $ = require('jquery');
var $window = $(window);
import 'materialize-css'; // It installs the JS asset only
import 'materialize-css/dist/css/materialize.min.css';

$window.on('load', function() {
    //menu (mobile)
    $('.menuMobileOpen').on('click', function() {
        $('.menu').toggle();
        $('.menu').addClass('menuMobile');
        if ($(this).html() === '<i class="icon-menu"></i>') {
            $(this).html('<i class="icon-cross"></i>');
        } else {
            $(this).html('<i class="icon-menu"></i>');
        }
    });

    //menu resize
    $(window).resize(function() {
        var viewportWidth = $(window).width();
        var viewportHeight = $(window).height();
        if (viewportWidth > 600 && viewportHeight > 750) {
            $('.menu').show();
            $('.menuMobileOpen').hide();
            $('.menu').removeClass('menuMobile');
        } else {
            $('.menuMobileOpen').show();
            $('.menu').addClass('menuMobile');

        }
    });

    //select
    $('.selectCustom').material_select();

    // slideshow
    var $radios = $('.slideshow').find('input[type=radio]');
    var numSlides = $radios.length;
    var i = 2;
    setInterval(function () {
        var dot = $('#dot' + i);
        dot.siblings('input[type=radio]').not(dot).prop('checked', false);
        dot.prop('checked', true);
        i = i + 1;
        if (i > numSlides) {
            i = 1;
        }
    }, 5000);
});
